import { React, FC, useEffect, useState } from 'Vendor';
import { authWrapper } from 'Utils';

const AuthenticationContext = React.createContext<ContextType>({
  authenticationStatus: false,
  accessToken: '',
  userId: '',
  isTraj: false,
  expiry: new Date(),
  setStatus: (
    status: boolean,
    token: string,
    userId: string,
    isTraj: boolean,
    expiry: Date
  ) => {},
});

const Authenticator: FC<React.ReactNode> = ({ children }) => {
  const [authenticationStatus, setAuthenticationStatus] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [userId, setUserId] = useState('');
  const [isTraj, setIsTraj] = useState(false);
  const [expiry, setExpiry] = useState(new Date());
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    const accessTokenFromStorage = authWrapper.getAuthTokensFromStorage();
    const userIdFromStorage = authWrapper.getUserIdFromStorage();
    const isTrajFromStorage = authWrapper.getIsTrajFromStorage();
    const expiryFromStorage = authWrapper.getExpiryFromStorage();
    if (accessTokenFromStorage) {
      setAuthenticationStatus(true);

      setAccessToken(accessTokenFromStorage);
      setUserId(userIdFromStorage);
      setIsTraj(isTrajFromStorage);
      setExpiry(expiryFromStorage);
    }
    setIsAuthenticating(false);
  }, []);

  const setStatus = (
    status: boolean,
    token: string,
    userId: string,
    isTraj: boolean,
    expiry: Date
  ) => {
    authWrapper.setUserInStorage(token, userId, isTraj, expiry);
    setAuthenticationStatus(status);
    setAccessToken(token);
    setUserId(userId);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        accessToken,
        authenticationStatus,
        userId,
        isTraj,
        expiry,
        setStatus,
      }}
    >
      {!isAuthenticating ? children : null}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, Authenticator };
