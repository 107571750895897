import { apiWrapper } from 'Utils';

const baseUrl = '/api/clientadmin';

const getClientAdminDashboard = async (team: string, waveId?: string, page = 1) => {
  let url = `${baseUrl}/waves/statusreport?team=${team}&page=${page}&size=10`;
  if (waveId) {
    url = `${url}&waveId=${waveId}`;
  }
  return await apiWrapper.getRequest<ClientAdminType>(url);
};

const getClientAdminRepReport = async (repId: string) => {
  return await apiWrapper.getRequest<ClientAdminRepReportType[]>(
    `${baseUrl}/waves/repreport/${repId}`
  );
};

const getScheduleWeek = async (
  status?: 'Active' | 'Inactive',
  team?: string
) => {
  let url = `${baseUrl}/waves/coachingschedule`;
  if (status) {
    url = `${url}?status=${status}`;
  }
  if (team) {
    if (status) {
      url = `${url}&team=${team}`;
    } else {
      url = `${url}?team=${team}`;
    }
  }
  return await apiWrapper.getRequest<CoachingScheduleType[]>(url);
};

const getTeams = async () => {
  return await apiWrapper.getRequest<TeamType[]>(`${baseUrl}/accounts/teams`);
};

const getWaves = async (
  waveId?: string,
  status?: 'Active' | 'Inactive',
  repId?: string,
  team?: string
) => {
  let url = `${baseUrl}/waves`;
  if (waveId) {
    url = `${url}?WaveId=${waveId}`;
  }
  if (status) {
    if (waveId) {
      url = `${url}&Status=${status}`;
    } else {
      url = `${url}?Status=${status}`;
    }
  }
  if (repId) {
    if (waveId || status) {
      url = `${url}&RepId=${repId}`;
    }else{
      url = `${url}?RepId=${repId}`;
    }
  }
  if (team) {
    if (waveId || status || repId) {
      url = `${url}&Team=${team}`;
    }else{
      url = `${url}?Team=${team}`;
    }
  }
  return await apiWrapper.getRequest<WaveType[]>(url);
};

const getReps = async (team?: string, status?: string) => {
  let url = `${baseUrl}/Accounts/Reps`;
  let hasTeam = false;
  if (team) {
    hasTeam = true;
    url = `${url}?Team=${team}`;
  }
  if (status) {
    if (hasTeam) {
      url = `${url}&Status=${status}`;
    } else {
      url = `${url}?Status=${status}`;
    }
  }

  return await apiWrapper.getRequest<RepType[]>(url);
};

export {
  getClientAdminDashboard,
  getClientAdminRepReport,
  getScheduleWeek,
  getTeams,
  getReps,
  getWaves,
};
