import { apiWrapper } from 'Utils';

const baseUrl = '/api/admin';

const createAccount = async (payload: AccountType) => {
  return await apiWrapper.postRequest('/api/admin/accounts', payload);
};
const editAccount = async (id: string, payload: AccountType) => {
  return await apiWrapper.putRequest(`/api/admin/accounts/${id}`, payload);
};
const deleteAccount = async (id: string) => {
  return await apiWrapper.deleteRequest(`/api/admin/accounts/${id}`);
};
const getAccount = async (id: string) => {
  return await apiWrapper.getRequest<AccountType>(`/api/admin/accounts/${id}`);
};
const getAccounts = async (
  role?: 'mgr' | 'rep' | 'admin',
  status?: 'Active' | 'Inactive',
  team?: 'Residential' | 'SMB'
) => {
  let url = `${baseUrl}/accounts`;
  if (status) {
    url = `${url}?status=${status}`;
  }
  if (role) {
    if (status) {
      url = `${url}&role=${role}`;
    } else {
      url = `${url}?role=${role}`;
    }
  }
  if (team) {
    if (role) {
      url = `${url}&team=${team}`;
    } else {
      url = `${url}?team=${team}`;
    }
  }
  return await apiWrapper.getRequest<AccountType[]>(url);
};
const getRoles = async () => {
  return await apiWrapper.getRequest<ListItemType[]>('/api/admin/roles');
};

export { createAccount, editAccount, deleteAccount, getAccounts, getAccount, getRoles };
