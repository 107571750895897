import {
  FC,
  useState,
  useEffect,
  classnames,
  useRef,
  useHistory,
} from 'Vendor';
import {
  Form,
  InputGroup,
  Button,
  FormControl,
  Spinner,
  ProgressBar,
  Modal,
  Row,
} from 'react-bootstrap';
import { MainTemplate } from 'Components';
import {
  getReps,
  getTeams,
  uploadCoachingSession,
  getWaves,
  checkRepWasCoached,
} from 'Api';
import styles from './Evaluate.module.scss';

const Evaluate: FC<React.ReactNode> = () => {
  const history = useHistory();
  const resetter = useRef<any>();
  const headerInfo: HeaderType = {
    title: 'Upload & Evaluate',
  };
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [recording, setRecording] = useState<any>();
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<TeamType[] | undefined>();
  const [team, setTeam] = useState<number | string>('');
  const [reps, setReps] = useState<RepType[] | undefined>();
  const [waveId, setWaveId] = useState<string>('');
  const [repId, setRepId] = useState<string>('-1');
  const [rep, setRep] = useState<string>('');
  const [evaluation, setEvaluation] = useState<
    'Successful' | 'Unsuccessful' | ''
  >('');
  const [uploadSuccess, setUploadSuccess] = useState<boolean>();
  const [formStatus, setFormStatus] = useState<string>('');
  const [repNotCoached, setRepNotCoached] = useState<boolean>(false);

  useEffect(() => {
    getFormWaves('Residential');
    getFormInfo();
  }, []);

  const getFormWaves = async (team: string) => {
    const resp = await getWaves(undefined, undefined, undefined, team);
    const waves = resp.data.filter((x) => x.currentWave);
    if (waves && waves.length > 0) {
      const wave = waves[0];
      if (wave && wave.id) {
        setWaveId(wave.id);
      }
    }
    setIsMounted(true);
  };

  const getFormInfo = async () => {
    //get data
    const respTeam = await getTeams();
    const teamInfo: TeamType[] = respTeam.data;

    const lstTeams = teamInfo.filter((x) => x.value !== 0);
    setTeams(lstTeams);
    if (lstTeams[0]) {
      const firstOption = lstTeams[0];
      setTeam(firstOption.value);
      await setRepDropdown(firstOption.name);
    }
  };
  const handleTeamChange = async (e: any) => {
    const obj = {
      text: e.target.selectedOptions[0].innerText,
      value: e.currentTarget.value,
    };
    setTeam(obj.value);
    await getFormWaves(obj.text);
    await setRepDropdown(obj.text);
  };

  const handleRepChange = async (e: any) => {
    const obj = {
      text: e.target.selectedOptions[0].innerText,
      value: e.currentTarget.value,
    };
    setFormStatus('');
    setRepId(obj.value);
    setRep(obj.text);
  };

  const setRepDropdown = async (selectedTeam: string) => {
    const respRep = await getReps(selectedTeam, 'Active');
    const repInfo: RepType[] = respRep.data;
    const lstReps = repInfo.filter((x) => x.id !== '0' && x.id !== '');
    setReps(lstReps);
    setRepId('-1');
    setRep('-1');
  };
  const onUploadProgress = (progressEvent: any) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    if (percent <= 100) {
      setPercentage(percent);
    }
  };
  const checkRepIfNotCoached = async (e: any) => {
    e.preventDefault();

    if (evaluation === '') {
      setFormStatus('Please select evaluation status');
    } else if (repId === '-1') {
      setFormStatus('Please select rep');
    } else {
      await checkRepWasCoached(repId).then((r) => {
        console.log(r);
        if (r.data) {
          console.log('handleSubmit');
          handleSubmit(e);
        } else {
          //show confirmation modal first
          setRepNotCoached(true);
        }
      });
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setRepNotCoached(false);
    try {
      setLoading(true);

      const data: CoachingSessionFormType = {
        repId,
        formFile: recording,
        status: evaluation,
      };

      await uploadCoachingSession(data, onUploadProgress).then(() => {
        history.push(`/evaluate/email/${repId}`);
        // setUploadSuccess(true);
        // setFormStatus('Uploaded successfully');
        // setTeam('');
        // setRepId('-1');
        // setEvaluation('');
        // resetter.current.value = null;
      });
    } catch (err: any) {
      console.log(err);
      setUploadSuccess(false);
      if (err.data?.message) {
        setFormStatus(err.data?.message);
      } else {
        setFormStatus('Something went wrong.');
      }
    }
    setLoading(false);
  };

  const formGroupStyle = classnames('mb-3', styles.fieldRow);
  const buttonStyle = classnames('btn', 'glow', styles.button);

  return (
    <MainTemplate headerInfo={headerInfo}>
      {isMounted ? (
        waveId !== '' ? (
          <>
            <Form className={styles.form} onSubmit={checkRepIfNotCoached}>
              <InputGroup className={formGroupStyle}>
                <label>Rep: </label>
                <Form.Select
                  title="Select Team"
                  value={team}
                  onChange={handleTeamChange}
                  required
                  className={styles.select}
                >
                  <option value="" disabled>
                    Select Team
                  </option>
                  {teams?.map((opt: TeamType) => (
                    <option value={opt.value} key={opt.value}>
                      {opt.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  title="Select Rep"
                  value={repId}
                  onChange={handleRepChange}
                  required
                  className={styles.select}
                >
                  <option value="-1" disabled>
                    Select Rep
                  </option>
                  {reps?.map((opt: RepType) => (
                    <option value={opt.id} key={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
              <InputGroup className={formGroupStyle}>
                <label>Evaluation: </label>
                <div className={styles.checkboxContainer}>
                  <label>
                    Successful
                    <Form.Check
                      id="Successful"
                      type="radio"
                      name="status"
                      value="Successful"
                      checked={evaluation === 'Successful'}
                      onChange={(e: any) => setEvaluation(e.target.value)}
                      className={styles.checkbox}
                    />
                  </label>
                </div>
                <label className={styles.or}>or</label>
                <div className={styles.checkboxContainer}>
                  <label>
                    Unsuccessful
                    <Form.Check
                      type="radio"
                      name="status"
                      value="Unsuccessful"
                      checked={evaluation === 'Unsuccessful'}
                      onChange={(e: any) => setEvaluation(e.target.value)}
                      className={styles.checkbox2}
                    />
                  </label>
                </div>
              </InputGroup>
              <InputGroup className={formGroupStyle}>
                <label>Select Recording: </label>
                <FormControl
                  ref={resetter}
                  onChange={(e: any) => setRecording(e.target.files[0])}
                  required
                  type="file"
                  max={1}
                  className={styles.fileUpload}
                  accept=".mp3, .wav"
                />
              </InputGroup>
              <InputGroup className={formGroupStyle}>
                <label>&nbsp;</label>
                <Button variant="black" type="submit" className={buttonStyle}>
                  {loading ? (
                    <Spinner animation="border" />
                  ) : (
                    'Upload & Evaluate'
                  )}
                </Button>
              </InputGroup>
              <InputGroup className={formGroupStyle}>
                <label>&nbsp;</label>
                {loading && (
                  <div className="mb-10">
                    <ProgressBar now={percentage} label={`${percentage}%`} />
                  </div>
                )}
                {formStatus !== '' ? (
                  uploadSuccess ? (
                    <p>{formStatus}</p>
                  ) : (
                    <p className="errorText">{formStatus}</p>
                  )
                ) : (
                  <p className="statusPlaceHolder">&nbsp;</p>
                )}
              </InputGroup>
            </Form>
            <Modal show={repNotCoached} onHide={() => setRepNotCoached(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Rep not coached</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <strong>{rep}</strong> has not been coached for this current
                  wave. Are you sure you want to proceed?
                </p>
                <Row>
                  <div className="justify-content-md-center">
                    <Button
                      variant="black"
                      onClick={handleSubmit}
                      className={styles.modalButton}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="black"
                      onClick={() => setRepNotCoached(false)}
                      className={styles.modalButton}
                    >
                      Cancel
                    </Button>
                  </div>
                </Row>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <h2 className={styles.h2Error}>No active waves created</h2>
        )
      ) : null}
    </MainTemplate>
  );
};

export default Evaluate;
