import { _, FC, useState, classnames, useEffect } from 'Vendor';
import { Table } from 'react-bootstrap';
import { getScheduleWeek } from 'Api';
import styles from './ClientAdmin.module.scss';

const Schedule: FC<{ teamId: string }> = ({ teamId }) => {
  const [schedDataTable, setSchedDataTable] =
    useState<CoachingScheduleType[]>();

  useEffect(() => {
    getSchedule(teamId);
  }, []);
  const getSchedule = async (teamId: string) => {
    try {
      const schedData = await getScheduleWeek('Active', teamId);
      const orderedSched = _.orderBy(schedData.data, (x) => x.startDate);
      setSchedDataTable(orderedSched);
    } catch (e: any) {
      setSchedDataTable([]);
    }
  };
  const styleTable = {
    dateCol: {
      width: '50%',
    },
    behaviorCol: {
      width: '50%',
    },
  };
  return (
    <div className={styles.schedule}>
      <Table striped bordered hover className={styles.table}>
        <thead>
          <tr>
            <th style={styleTable.dateCol}>Date</th>
            <th style={styleTable.behaviorCol}>Behavior</th>
          </tr>
        </thead>
        <tbody>
          {schedDataTable && schedDataTable.length > 0 ? (
            schedDataTable.map((item: CoachingScheduleType) => (
              <tr key={item.id}>
                <td>{item.wave}</td>
                <td>{item.subject}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>No waves found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default Schedule;
