import { FC, useState, useEffect, useHistory, classnames } from 'Vendor';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { MainTemplate } from 'Components';
import { getReps, getTeams } from 'Api';
import styles from './Coach.module.scss';
import { localStorageService } from 'Utils';

const Coach: FC<React.ReactNode> = () => {
  const history = useHistory();
  const headerInfo: HeaderType = {
    title: 'Coach A Rep',
  };

  const [teams, setTeams] = useState<TeamType[] | undefined>();
  const [team, setTeam] = useState<number>();
  const [reps, setReps] = useState<RepType[] | undefined>();
  const [repId, setRepId] = useState<string>('');
  const [formStatus, setFormStatus] = useState<string>('');

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = async () => {
    //get data
    const respTeam = await getTeams();
    const teamInfo: TeamType[] = respTeam.data;
    const lstTeams = teamInfo.filter((x) => x.value !== 0);
    setTeams(lstTeams);
    if (lstTeams[0]) {
      const firstOption = lstTeams[0];
      setTeam(firstOption.value);
      await setRepDropdown(firstOption.name);
    }
  };
  const handleTeamChange = async (e: any) => {
    const obj = {
      text: e.target.selectedOptions[0].innerText,
      value: e.currentTarget.value,
    };
    setTeam(obj.value);
    const text: string = obj.text;
    await setRepDropdown(text);
  };

  const setRepDropdown = async (selectedTeam: string) => {
    const respRep = await getReps(selectedTeam, 'Active');
    const repInfo: RepType[] = respRep.data;
    let lstReps = repInfo.filter((x) => x.id !== '0' && x.id !== '');
    console.log(selectedTeam);
    if (selectedTeam === 'SMB') {
      lstReps.push({
        id: '1234567890ABCDEFGHIJ1234567890',
        name: 'Practice Rep',
      });
    } else {
      lstReps.push({
        id: '0987654321ABCDEFGHIJ0987654321',
        name: 'Practice Rep',
      });
    }
    setReps(lstReps);
    if (lstReps.length > 0) {
      const firstOption = lstReps[0];
      setRepId(firstOption.id);
    } else {
      setRepId('');
    }
  };

  const handleRepChange = (e: any) => {
    setRepId(e.currentTarget.value);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      //localStorageService.setItem('team-selected-coach', team);
      history.push('/coach/session/' + repId);
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setFormStatus(err.data?.message);
      } else {
        setFormStatus('Something went wrong.');
      }
    }
  };

  const buttonStyle = classnames('btn', 'glow', styles.button);

  return (
    <MainTemplate headerInfo={headerInfo}>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <Form.Select
            title="Select Team"
            onChange={handleTeamChange}
            required
            className={styles.select}
          >
            <option value="" disabled>
              Select Team
            </option>
            {teams?.map((opt: TeamType) => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Select
            title="Select Rep"
            onChange={handleRepChange}
            required
            className={styles.select}
          >
            <option value="" disabled>
              Select Rep
            </option>
            {reps?.map((opt: RepType) => (
              <option value={opt.id} key={opt.id}>
                {opt.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <Button variant="black" type="submit" className={buttonStyle}>
            Select Rep
          </Button>
        </InputGroup>
        <InputGroup className="mb-3">
          {formStatus !== '' ? (
            <p className="errorText">{formStatus}</p>
          ) : (
            <p className="statusPlaceHolder">&nbsp;</p>
          )}
        </InputGroup>
      </Form>
    </MainTemplate>
  );
};

export default Coach;
