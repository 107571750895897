import { _, FC, useEffect, useState, ReactPlayer } from 'Vendor';
import { RepGrid, MediaPopup } from 'Components';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { Button, Form } from 'react-bootstrap';
import {
  getClientAdminDashboard,
  getScheduleWeek,
  getSampleVideoSettings,
} from 'Api';
import { localStorageService, formatDate } from 'Utils';
import { Chart } from 'chart.js';
import styles from './ClientAdmin.module.scss';

Chart.register(ChartDataLabels);

const popupIntialState = {
  show: false,
  name: '',
  fileUrl: '',
};
const apiUrl = process.env.REACT_APP_BASE_API_URL;
const Report: FC<React.ReactNode> = () => {
  //state
  const [popupProps, setPopupProps] = useState(popupIntialState);
  const [selectedTeamType, setSelectedTeamType] = useState<any>({});
  const [waveId, setWaveId] = useState<string>('');
  const [repInfo, setRepInfo] = useState<RepGridType>();
  const [historyRep, setHistoryRep] = useState<DropdownType | undefined>();
  const [graphData, setGraphData] = useState<any | undefined>();
  const [scheduleWeek, setSchedWeek] = useState<DropdownType | undefined>();
  const [noWaves, setNoWaves] = useState<boolean>(false);
  const [highestPercentage, setHighestPercentage] = useState<number>(100);
  const [sampleVideoSetting, setSampleVideoSetting] =
    useState<SettingsSampleEmail>({
      firstText: 'Introduction',
      firstFilePath: 'samples/intro.mp4',
      secondText: 'MTA Examples',
      secondFilePath: 'samples/sample.mp4',
    });

  useEffect(() => {
    const team = localStorageService.getItem('team-selected');
    setSelectedTeamType(team);
    getSchedule(team.teamId);
    getDashboardInfo(team.teamId);
    console.log(selectedTeamType);
  }, []);
  const setSampleVideo = async (wId: string) => {
    const resp = await getSampleVideoSettings(wId);
    setSampleVideoSetting(resp.data);
  };
  const getSchedule = async (teamId: string) => {
    const resp2 = await getScheduleWeek('Active', teamId);
    const schedWeek: CoachingScheduleType[] = resp2.data;

    const mappedSched = _.orderBy(schedWeek, (x) => x.startDate, 'desc').map(
      (x) => {
        const dateRange = formatDate(x.startDate, x.endDate);
        return {
          text: `${x.subject} (${dateRange})`,
          value: x.id,
        };
      }
    );

    setSchedWeek({
      initialText: 'Previous Waves',
      items: mappedSched,
    });
  };
  const getDashboardInfo = async (
    teamId: string,
    waveId?: string,
    page = 1
  ) => {
    try {
      //get data
      const resp = await getClientAdminDashboard(teamId, waveId, page);
      const dashboardInfo: ClientAdminType = resp.data;

      setWaveId(dashboardInfo.waveId);
      setSampleVideo(dashboardInfo.waveId);

      const stat = dashboardInfo.statistics;
      setGraphData({
        labels: ['Inactive', 'Coached', 'Unsuccessful', 'Successful'],
        datasets: [
          {
            label: '',
            data: [
              stat.inactive,
              stat.coached,
              stat.unsuccessful,
              stat.successful,
            ],
            backgroundColor: ['#000', '#00538f', '#c00000', '#005d2f'],
          },
        ],
      });
      let highestVal: number = stat.inactive;
      if (stat.coached > highestVal) {
        highestVal = stat.coached;
      }
      if (stat.successful > highestVal) {
        highestVal = stat.successful;
      }
      if (stat.unsuccessful > highestVal) {
        highestVal = stat.unsuccessful;
      }
      setHighestPercentage(highestVal + 10);

      const rep = dashboardInfo.reps;
      const orderedReps = _.orderBy(rep.items, (x) => x.name);
      const mappedReps = orderedReps.map((x) => {
        return {
          text: x.name,
          value: x.repId,
        };
      });

      setHistoryRep({
        initialText: 'History By Rep',
        items: mappedReps,
      });

      setRepInfo({
        items: rep.items,
        page: rep.page,
        size: rep.size,
        totalPages: rep.totalPages,
        subject: dashboardInfo.subject,
        waveId: waveId,
        team: teamId,
        handlePaginationClick: handlePaginationClick,
      });
    } catch (e) {
      console.log(e);
      setNoWaves(true);
    }
  };

  const changeWave = async (e: any) => {
    const obj = {
      text: e.target.selectedOptions[0].innerText,
      value: e.currentTarget.value,
    };
    getDashboardInfo(selectedTeamType.teamId, obj.value, 1);
  };
  const graphOptions = {
    backgroundColor: '#fff',
    responsive: true,
    maintainAspectRatio: true,
    showTooltips: false,
    indexAxis: 'y',
    layout: {
      padding: 5,
    },
    plugins: {
      tooltip: { enabled: false },
      title: {
        display: true,
        text: `${selectedTeamType.team} Team Status`,
        color: '#000',
        font: {
          size: 24,
          weight: 'normal',
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        formatter(value: any, context: Context) {
          return Math.round(value) + '%';
        },
        display: true,
        color: 'black',
        align: 'end',
        anchor: 'end',
        font: { size: 14 },
      },
    },
    scales: {
      x: {
        min: 0,
        max: highestPercentage,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          font: {
            size: 14,
          },
        },
      },
    },
  };
  const playIcon = require('../../assets/images/play.png');

  const handleIntroductionClick = () => {
    setPopupProps({
      show: true,
      name: '',
      fileUrl: `${apiUrl}/${sampleVideoSetting.firstFilePath}`,
    });
  };
  const handleMTAExamplesClick = () => {
    setPopupProps({
      show: true,
      name: '',
      fileUrl: `${apiUrl}/${sampleVideoSetting.secondFilePath}`,
    });
  };

  const handlePopupClose = () => {
    setPopupProps(popupIntialState);
  };

  const handlePaginationClick = async (
    tId: string, //teamId
    wId: string, //waveId
    num: number
  ) => {
    getDashboardInfo(tId, wId, num);
  };

  return (
    <>
      {!noWaves ? (
        <>
          <div className={styles.labelrow}>
            <Form.Select
              title="Select Behavior"
              value={waveId}
              className={styles.select}
              onChange={changeWave}
            >
              {scheduleWeek?.items?.map((opt: DropdownItemType) => (
                <option value={opt.value} key={opt.value}>
                  {opt.text}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className={styles.mainrow}>
            <div className={styles.graphcolumn}>
              <Bar
                data={graphData}
                options={graphOptions}
                plugins={[ChartDataLabels]}
              />
              <div className={styles.buttonrow}>
                <Button
                  variant="black"
                  onClick={() => handleIntroductionClick()}
                >
                  {sampleVideoSetting.firstText}
                  <img
                    className={styles.playIcon}
                    src={playIcon.default}
                    alt="Play Icon"
                  />
                </Button>
                <Button
                  variant="black"
                  onClick={() => handleMTAExamplesClick()}
                >
                  {sampleVideoSetting.secondText}
                  <img
                    className={styles.playIcon}
                    src={playIcon.default}
                    alt="Play Icon"
                  />
                </Button>
              </div>
            </div>
            <div className={styles.gridcolumn}>
              {repInfo && <RepGrid {...repInfo}></RepGrid>}
            </div>
          </div>

          <MediaPopup
            handleClose={handlePopupClose}
            show={popupProps.show}
            title={popupProps.name}
            mediaType={popupProps.fileUrl.includes('mp4') ? 'video' : 'audio'}
          >
            <ReactPlayer
              url={popupProps.fileUrl}
              width="100%"
              height="100%"
              playing={false}
              controls={true}
            />
          </MediaPopup>
        </>
      ) : (
        <h2 className={styles.h2Error}>No active waves created</h2>
      )}
    </>
  );
};

export default Report;
