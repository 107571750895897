import { FC, useState, useEffect, ReactPlayer } from 'Vendor';
import { Table, Button, Row } from 'react-bootstrap';
import {
  getWaves,
  uploadWaveRecord,
  replaceWaveRecord,
  deleteWave,
  deleteWaveRecord,
} from 'Api';
import { formatDate } from 'Utils';
import styles from './AdminWave.module.scss';
import { MainTemplate, MediaPopup } from 'Components';
import { default as AddOrReplaceWaveRecording } from './popups/AddOrReplaceWaveRecording';
import { default as DeleteWaveRecording } from './popups/DeleteWaveRecording';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as ReplaceIcon } from '../../assets/images/replace.svg';
import { ReactComponent as PlayIcon } from '../../assets/images/play.svg';
const popupIntialState = {
  show: false,
  name: '',
  fileUrl: '',
};
const popupAddVideoInitialState = {
  show: false,
  title: 'Add Video',
  id: '',
};
const popupReplaceVideoInitialState = {
  show: false,
  order: '0',
  title: 'Replace Video',
  id: '',
};
const popupDeleteVideoInitialState = {
  show: false,
  id: '',
  title: 'Delete Video',
};

const popupDeleteWaveInitialState = {
  show: false,
  id: '',
  title: 'Delete Wave',
};

const apiUrl = process.env.REACT_APP_BASE_API_URL;

const AdminWave: FC<{ teamId: string }> = (teamId) => {
  const [schedDataTable, setSchedDataTable] = useState<WaveType[]>();
  const [popupProps, setPopupProps] = useState(popupIntialState);
  const [popupAddVideo, setPopupAddVideo] = useState(popupAddVideoInitialState);
  const [popupReplaceVideo, setPopupReplaceVideo] = useState(
    popupReplaceVideoInitialState
  );
  const [popupDeleteVideo, setPopupDeleteVideo] = useState(
    popupDeleteVideoInitialState
  );
  const [popupDeleteWave, setPopupDeleteWave] = useState(
    popupDeleteWaveInitialState
  );
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getSchedule();
  }, []);
  const getSchedule = async () => {
    try {
      const schedData = await getWaves();
      setSchedDataTable(schedData.data);
    } catch (e: any) {
      setSchedDataTable([]);
    }
  };
  const styleTable = {
    dateCol: {
      width: '20%',
    },
    behaviorCol: {
      width: '50%',
    },
    teamCol: {
      width: '12%',
    },
    statusCol: {
      width: '8%',
    },
    editCol: {
      width: '10%',
    },
  };

  //close popup events
  const handlePopupClose = () => {
    setPopupProps(popupIntialState);
  };

  const handlePopupAddVideoClose = () => {
    setPopupAddVideo(popupAddVideoInitialState);
  };

  const handlePopupReplaceVideoClose = () => {
    setPopupReplaceVideo(popupReplaceVideoInitialState);
  };

  const handlePopupDeleteVideoClose = () => {
    setPopupDeleteVideo(popupDeleteVideoInitialState);
  };
  const handlePopupDeleteWaveClose = () => {
    setPopupDeleteWave(popupDeleteWaveInitialState);
  };

  //upload progress
  const onUploadProgress = (progressEvent: any) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    if (percent <= 100) {
      setUploadPercentage(percent);
    }
  };

  //save events
  const handlePopupAddVideoSave = async (
    id: string,
    order: string,
    video: string | Blob
  ) => {
    try {
      setUploadLoading(true);
      await uploadWaveRecord(
        {
          waveId: id,
          order: order,
          formFile: video,
        },
        onUploadProgress
      ).then(() => {
        getSchedule();
        setPopupAddVideo(popupAddVideoInitialState);
        setUploadLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setErrorMessage(err.data?.message);
      } else {
        setErrorMessage('Something went wrong.');
      }
    }
  };
  const handlePopupReplaceVideoSave = async (
    id: string,
    order: string,
    video: string | Blob
  ) => {
    try {
      setUploadLoading(true);
      await replaceWaveRecord(
        {
          waveRecordingId: id,
          order: order,
          formFile: video,
        },
        onUploadProgress
      ).then(() => {
        getSchedule();
        setPopupReplaceVideo(popupReplaceVideoInitialState);
        setUploadLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setErrorMessage(err.data?.message);
      } else {
        setErrorMessage('Something went wrong.');
      }
    }
  };

  const handlePopupDeleteVideo = async (id: string) => {
    try {
      await deleteWaveRecord(id).then(() => {
        getSchedule();
        setPopupDeleteVideo(popupReplaceVideoInitialState);
      });
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setErrorMessage(err.data?.message);
      } else {
        setErrorMessage('Something went wrong.');
      }
    }
  };

  const handlePopupDeleteWave = async (id?: string) => {
    try {
      const waveId = id || '';
      await deleteWave(waveId).then(() => {
        getSchedule();
        setPopupDeleteWave(popupDeleteWaveInitialState);
      });
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setErrorMessage(err.data?.message);
      } else {
        setErrorMessage('Something went wrong.');
      }
    }
  };

  return (
    <MainTemplate scrollableBody={true} unCenteredElements={true}>
      <h3 className={styles.title}>Wave Admin</h3>
      <Row className={styles.rowButton}>
        <div>
          <Button href="/admin/create-wave">Create New Wave</Button>
          <Button href="/admin" variant="warning">
            Back
          </Button>
        </div>
      </Row>
      <Table striped bordered hover className={styles.table}>
        <thead>
          <tr>
            <th style={styleTable.dateCol}>Date</th>
            <th style={styleTable.behaviorCol}>Behavior</th>
            <th style={styleTable.teamCol}>Team</th>
            <th style={styleTable.statusCol}>Status</th>
            <th style={styleTable.editCol}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {schedDataTable && schedDataTable.length > 0 ? (
            schedDataTable.map((item: WaveType) => (
              <tr key={item.id}>
                <td>{formatDate(item.startDate, item.endDate)}</td>
                <td>
                  {item.subject}
                  {item.recordings && item.recordings.length > 0 ? (
                    <table className={styles.subTable}>
                      <thead>
                        <tr>
                          <th>Filename</th>
                          <th>Order</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.recordings.map(
                          (recordingItem: WaveRecordingType) => (
                            <tr key={recordingItem.id}>
                              <td>{recordingItem.fileName}</td>
                              <td>{recordingItem.order}</td>
                              <td className={styles.buttonColumn}>
                                <Button
                                  onClick={() => {
                                    setPopupProps({
                                      name: '',
                                      fileUrl: `${apiUrl}/${recordingItem.path}`,
                                      show: true,
                                    });
                                  }}
                                  className={styles.playButton}
                                  title="Play Video"
                                >
                                  <PlayIcon />
                                </Button>
                                <Button
                                  onClick={() => {
                                    setPopupReplaceVideo({
                                      title: 'Replace Video',
                                      id: recordingItem.id,
                                      order: recordingItem.order.toString(),
                                      show: true,
                                    });
                                  }}
                                  className={styles.replaceButton}
                                  title="Replace Video"
                                >
                                  <ReplaceIcon />
                                </Button>
                                <Button
                                  onClick={() => {
                                    setPopupDeleteVideo({
                                      title: 'Delete Video',
                                      id: recordingItem.id,
                                      show: true,
                                    });
                                  }}
                                  className={styles.deleteButton}
                                  title="Delete Video"
                                >
                                  <DeleteIcon />
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                        <tr className={styles.addVideoRow}>
                          <td>
                            <Button
                              onClick={() => {
                                setPopupAddVideo({
                                  title: 'Add Video',
                                  id: item.id || '',
                                  show: true,
                                });
                              }}
                              className={styles.addButton}
                              title="Add Video"
                            >
                              Add Video
                            </Button>
                          </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <p>No videos</p>
                      <Button
                        onClick={() => {
                          setPopupAddVideo({
                            title: 'Add Video',
                            id: item.id || '',
                            show: true,
                          });
                        }}
                        className={styles.addButton}
                        title="Add Video"
                      >
                        Add Video
                      </Button>
                    </>
                  )}
                </td>
                <td>{item.team}</td>
                <td>{item.status}</td>
                <td className={styles.buttonColumn}>
                  <Button
                    href={`/admin/edit-wave/${item.id}`}
                    className={styles.editButton}
                    title="Edit Wave"
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    onClick={() =>
                      setPopupDeleteWave({
                        title: 'Delete Wave',
                        id: item.id || '',
                        show: true,
                      })
                    }
                    className={styles.deleteButton}
                    title="Delete Wave"
                  >
                    <DeleteIcon />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>No waves found</td>
            </tr>
          )}
        </tbody>
      </Table>
      <MediaPopup
        handleClose={handlePopupClose}
        show={popupProps.show}
        title={popupProps.name}
        mediaType={popupProps.fileUrl.includes('mp4') ? 'video' : 'audio'}
      >
        <ReactPlayer
          url={popupProps.fileUrl}
          width="100%"
          height="100%"
          playing={false}
          controls={true}
        />
      </MediaPopup>
      <AddOrReplaceWaveRecording
        {...popupAddVideo}
        order="0"
        percentage={uploadPercentage}
        loading={uploadLoading}
        formStatus={errorMessage}
        handleClose={handlePopupAddVideoClose}
        handleSave={handlePopupAddVideoSave}
      />
      <AddOrReplaceWaveRecording
        {...popupReplaceVideo}
        percentage={uploadPercentage}
        loading={uploadLoading}
        formStatus={errorMessage}
        handleClose={handlePopupReplaceVideoClose}
        handleSave={handlePopupReplaceVideoSave}
      />
      <DeleteWaveRecording
        {...popupDeleteVideo}
        formStatus={errorMessage}
        handleClose={handlePopupDeleteVideoClose}
        handleDelete={handlePopupDeleteVideo}
      />
      <DeleteWaveRecording
        {...popupDeleteWave}
        formStatus={errorMessage}
        handleClose={handlePopupDeleteWaveClose}
        handleDelete={handlePopupDeleteWave}
      />
    </MainTemplate>
  );
};
export default AdminWave;
