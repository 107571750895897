import { FC, useState } from 'Vendor';
import { MainTemplate } from 'Components';
import { toolsResetAll } from 'Api';
import { ListGroup, Modal, Button, Row, Col } from 'react-bootstrap';
import stylesMod from './AdminWave.module.scss';
const AdminLanding: FC<React.ReactNode> = () => {
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] =
    useState<boolean>(false);

  const handleCloseResetAllPopup = () => {
    setShowDeletePopup(false);
  };
  const handleOpenResetAllPopup = () => {
    setShowDeletePopup(true);
  };
  const handleConfirmReset = async () => {
    await toolsResetAll().then(() => {
      setShowDeleteConfirmPopup(true);
    });
  };
  const styles = {
    button: {
      marginRight: 10,
    },
    marginated: {
      marginTop: '10%',
    },
  };
  return (
    <MainTemplate unCenteredElements={true} className={stylesMod.mainContainer}>
      <p>Last Updated (EST): 2021-11-24 17:06</p>
      <ListGroup>
        <ListGroup.Item action variant="dark" href="/admin/account">
          Accounts
        </ListGroup.Item>
        <ListGroup.Item action variant="dark" href="/admin/wave">
          Wave
        </ListGroup.Item>
        <ListGroup.Item action variant="dark" href="/admin/coaching-activity">
          Coaching Activity
        </ListGroup.Item>
        <ListGroup.Item action variant="dark" href="/admin/evaluation-activity">
          Evaluation Activity
        </ListGroup.Item>
      </ListGroup>
      <ListGroup style={styles.marginated}>
        <ListGroup.Item
          action
          variant="danger"
          onClick={() => handleOpenResetAllPopup()}
        >
          Reset All
        </ListGroup.Item>
      </ListGroup>
      <Modal show={showDeletePopup} onHide={handleCloseResetAllPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showDeleteConfirmPopup ? (
            <>
              <p>
                Resetting will delete all waves, wave recordings, coaching
                sessions and evaluations. Are you sure you want to do this?
              </p>
              <Row>
                <div className="justify-content-md-center">
                  <Button
                    variant="danger"
                    onClick={handleConfirmReset}
                    style={styles.button}
                  >
                    Yes
                  </Button>
                  <Button variant="success" onClick={handleCloseResetAllPopup}>
                    Cancel
                  </Button>
                </div>
              </Row>
            </>
          ) : (
            <>
              <p>Reset done</p>
              <Row className="d-flex">
                <Col>
                  <Button variant="primary" onClick={handleCloseResetAllPopup}>
                    Close
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
      </Modal>
    </MainTemplate>
  );
};

export default AdminLanding;
