import { apiWrapper } from 'Utils';

const baseUrl = '/api/clientadmin/coachingsessions';

const addCoachingSessionByRep = async (repId: string) => {
  return await apiWrapper.postRequest(baseUrl, {repId, status: 'Coached'});
};

const checkRepWasCoached = async (repId: string) => {
  return await apiWrapper.getRequest(`${baseUrl}/status/${repId}`);
}

const sendEvaluationMessage = async (comment: string, repId: string, waveId: string) => {
  return await apiWrapper.postRequest(`${baseUrl}/emails`, {repId, waveId, comment});
}

const uploadCoachingSession = async (payload: CoachingSessionFormType, onUploadProgress:any) => {
  const formData = new FormData();
  formData.append('repId', payload.repId);
  formData.append('formFile', payload.formFile);
  formData.append('status', payload.status);
  return await apiWrapper.postRequest(`${baseUrl}/recordings`, formData, "multipart/form-data", null, null, onUploadProgress);
};

export {  checkRepWasCoached, addCoachingSessionByRep, sendEvaluationMessage,  uploadCoachingSession };
