import { FC, useState, useRef } from 'Vendor';
import { Modal, CloseButton, Row, Button } from 'react-bootstrap';
import styles from './WaveRecording.module.scss';

const DeleteWaveRecording: FC<{
  title: string;
  id: string;
  show: boolean;
  formStatus: string;
  handleClose: any;
  handleDelete: any;
}> = ({ title, id, show, formStatus, handleClose, handleDelete }) => {
  
  return (
    <Modal
      className={styles.popup}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <CloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete this?
        </p>
        <Row>
          <div className="justify-content-md-center">
            <Button className={styles.yesButton} variant="danger" onClick={() => handleDelete(id)}>
              Yes
            </Button>
            <Button variant="success" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteWaveRecording;
