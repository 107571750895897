import { Route, PropTypes, Redirect, FC } from 'Vendor';
import { AuthenticationContext } from 'Components';

const ProtectedRoute: FC<{ component: any; path: string; exact?: boolean }> = ({
  component: Component,
  path,
  ...rest
}) => {
  return (
    <AuthenticationContext.Consumer>
      {(value) => (
        <Route
          exact
          {...rest}
          render={(props) =>
            value.authenticationStatus ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: `/login`,
                }}
              />
            )
          }
        />
      )}
    </AuthenticationContext.Consumer>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
};

export default ProtectedRoute;
