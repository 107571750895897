import { FC, classnames } from 'Vendor';
import { MainTemplate } from 'Components';
import { Button } from 'react-bootstrap';
import styles from './Evaluate.module.scss';

const EvaluateDone: FC<React.ReactNode> = () => {
  const headerInfo: HeaderType = {
    title: 'Upload & Evaluate',
  };
  const buttonStyle = classnames('btn', 'glow', styles.buttonDone);
  return (
    <MainTemplate headerInfo={headerInfo}>
      <div className={styles.evaluationDone}>
        <br />
        <p className={styles.paragraph}>
          <strong>Evaluation complete</strong>
        </p>
        <br />
        <Button variant="black" type="submit" className={buttonStyle} href="/">
          Done
        </Button>
      </div>
    </MainTemplate>
  );
};

export default EvaluateDone;
