import { localStorageService } from "./storageService";

const authWrapper = {
  getAuthTokensFromStorage: (): string | null => {
    return _getAuthTokensFromStorage();
  },
  getUserIdFromStorage: (): string => {
    return _getUserIdFromStorage();
  },
  getIsTrajFromStorage: (): boolean => {
    return _getIsTrajFromStorage();
  },
  getExpiryFromStorage: (): Date => {
    return _getExpiryFromStorage();
  },
  getAuthFromStorage: () => {
    return _getAuthFromStorage();
  },
  setUserInStorage: (token: string, id: string, isTraj: boolean, expiry: Date) => {
    return setUserInStorage(token, id, isTraj, expiry);
  },
  removeAuthTokensFromStorage: () => {
    return clearUserFromStorage();
  },
  generateHeaders: () => {
    return _generateAuthHeaders();
  }
}
//access token
function _accessTokenHandler(token?: string): string | void {
  if (token === undefined) {
    return localStorageService.getItem("accessToken");
  }
  return localStorageService.setItem("accessToken", token);
}

const _getAuthTokensFromStorage = () => { 
  return _accessTokenHandler() || null;
};

const _clearAuthTokensFromStorage = () => {
  localStorageService.removeItem("accessToken");
};

//user id
function _userIdHandler(id?: string): string | void {
  if (id === undefined) {
    return localStorageService.getItem("userId");
  }
  return localStorageService.setItem("userId", id);
}

const _getUserIdFromStorage = () => {
  return _userIdHandler() || '';
};

const _clearUserIdFromStorage = () => {
  localStorageService.removeItem("userId");
};

//is Traj
function _isTrajHandler(isTraj?: boolean): boolean | void {
  if (isTraj === undefined) {
    return localStorageService.getItem("isTraj");
  }
  return localStorageService.setItem("isTraj", isTraj);
}

const _getIsTrajFromStorage = () => {
  return _isTrajHandler() || false;
};

const _clearIsTrajFromStorage = () => {
  localStorageService.removeItem("isTraj");
};

//expiry
function _expiryHandler(expiry?: Date): Date | void {
  if (expiry === undefined) {
    return localStorageService.getItem("expiry");
  }
  return localStorageService.setItem("expiry", expiry);
}

const _getExpiryFromStorage = () => {
  return _expiryHandler() || new Date();
};

const _clearExpiryFromStorage = () => {
  localStorageService.removeItem("expiry");
};

const _getAuthFromStorage = () => {
  const token = _getAuthTokensFromStorage();
  const userId = _getUserIdFromStorage();
  const isTraj = _getIsTrajFromStorage();
  const expiry = _getExpiryFromStorage();

  return {
    token: token || null,
    userId: userId || null,
    isTraj: isTraj || null,
    expiry: expiry || null
  };
};

const setUserInStorage = (token: string, id: string, isTraj: boolean, expiry: Date) => {
  _accessTokenHandler(token);
  _userIdHandler(id);
  _isTrajHandler(isTraj);
  _expiryHandler(expiry);
};

const clearUserFromStorage = () => {
  _clearAuthTokensFromStorage();
  _clearUserIdFromStorage();
  _clearIsTrajFromStorage();
  _clearExpiryFromStorage();
};

const _generateAuthHeaders = () => ({
  Authorization: "bearer " + _getAuthTokensFromStorage(),
});


export default authWrapper;