import {
  React,
  useContext,
  useState,
  useEffect,
  useRef,
  classnames,
} from 'Vendor';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { postLogin } from 'Api';
import { AuthenticationContext, MainTemplate } from 'Components';
import styles from './Login.module.scss';

const Login: React.FC<React.ReactNode> = () => {
  const isMounted = useRef(false);
  const authContext = useContext(AuthenticationContext);
  const [empno, setEmpNo] = useState('');
  const [formStatus, setFormStatus] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Becomes true on component mount
    isMounted.current = true;
    // becomes false on unmount
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await postLogin({
        username: empno,
      }).then((response) => {
        setLoading(true);
        setFormStatus('');
        const data = response.data;
        console.log(data);
        authContext.setStatus(
          true,
          data.token,
          data.id,
          data.isTraj,
          data.expiry
        );
        setLoading(false);
      });
    } catch (err: any) {
      if (err.data?.message) {
        setFormStatus(err.data?.message);
      } else {
        setFormStatus('Something went wrong.');
      }
    }
  };
  const buttonStyle = classnames('btn', 'glow', styles.loginButton);
  return (
    <MainTemplate className={styles.loginPage}>
      <Form onSubmit={onSubmit}>
        <h2>Welcome</h2>
        <InputGroup className="mb-3">
          <Form.Control
            onChange={(e: any) => setEmpNo(e.target.value)}
            value={empno}
            type="text"
            required={true}
            placeholder="Enter Your Employee #"
          />
        </InputGroup>
        <Button
          variant="black"
          type="submit"
          className={buttonStyle}
          disabled={loading}
        >
          Coach Login
        </Button>
        {formStatus !== '' ? (
          <p className="errorText">{formStatus}</p>
        ) : (
          <p className="statusPlaceHolder">&nbsp;</p>
        )}
      </Form>
    </MainTemplate>
  );
};

export default Login;
