import { useState, useEffect } from 'Vendor';
import { Button, Row, Table, Modal, CloseButton } from 'react-bootstrap';
import styles from '../AdminWave.module.scss';
import popupStyles from '../popups/WaveRecording.module.scss';
import { MainTemplate } from 'Components';
import { getAccounts, deleteAccount } from 'Api';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';

const popupDeleteInitialState = {
  show: false,
  id: '',
  title: 'Delete Account',
};

const AdminAccount = () => {
  const [accountTable, setAccountTable] = useState<AccountType[]>();
  const [popupDelete, setPopupDelete] = useState(popupDeleteInitialState);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    getAccountTable();
  }, []);
  const getAccountTable = async () => {
    try {
      const resp = await getAccounts();
      setAccountTable(resp.data);
    } catch (e: any) {
      setAccountTable([]);
    }
  };

  const handlePopupDeleteClose = () => {
    setPopupDelete(popupDeleteInitialState);
  };

  const handlePopupDelete = async () => {
    try {
      await deleteAccount(popupDelete.id).then(() => {
        getAccountTable();
        setPopupDelete(popupDeleteInitialState);
      });
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setErrorMessage(err.data?.message);
      } else {
        setErrorMessage('Something went wrong.');
      }
    }
  };

  const styleTable = {
    idCol: {
      width: '10%',
    },
    repCol: {
      width: '15%',
    },
    emailCol: {
      width: '20%',
    },
    teamCol: {
      width: '10%',
    },
    roleCol: {
      width: '10%',
    },
    managerCol: {
      width: '15%',
    },
    statusCol: {
      width: '10%',
    },
    buttonsCol: {
      width: '10%',
    },
  };
  return (
    <MainTemplate scrollableBody={true} unCenteredElements={true}>
      <h3 className={styles.title}>Accounts</h3>
      <Row className={styles.rowButton}>
        <div>
          <Button href="/admin/create-account">Create New Account</Button>
          <Button href="/admin" variant="warning">
            Back
          </Button>
        </div>
      </Row>
      <Table striped bordered hover className={styles.table} responsive>
        <thead>
          <tr>
            <th style={styleTable.idCol}>ID</th>
            <th style={styleTable.repCol}>Name</th>
            <th style={styleTable.emailCol}>Email</th>
            <th style={styleTable.teamCol}>Team</th>
            <th style={styleTable.roleCol}>Role</th>
            <th style={styleTable.managerCol}>Manager</th>
            <th style={styleTable.statusCol}>Status</th>
            <th style={styleTable.buttonsCol}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {accountTable && accountTable.length > 0 ? (
            accountTable.map((item: AccountType) => (
              <tr key={item.id}>
                <td>{item.userName}</td>
                <td>
                  {item.firstName} {item.lastName}
                </td>
                <td>{item.email}</td>
                <td>{item.team}</td>
                <td>{item.role ? item.role : '-'}</td>
                <td>{item.manager ? item.manager : 'n/a'}</td>
                <td className={item.status}>{item.status}</td>
                <td className={styles.buttonColumn}>
                  {item.status !== 'Deleted' ? (
                    <>
                      <Button
                        href={`/admin/edit-account/${item.id}`}
                        className={styles.editButton}
                        title="Edit Wave"
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        className={styles.deleteButton}
                        title="Delete Wave"
                        onClick={() =>
                          setPopupDelete({
                            title: `Delete ${item.firstName} ${item.lastName}`,
                            id: item.id || '',
                            show: true,
                          })
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </>
                  ) : null}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>No accounts found</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        className={popupStyles.popup}
        show={popupDelete.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>{popupDelete.title}</Modal.Title>
          <CloseButton onClick={handlePopupDeleteClose} />
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this?</p>
          <Row>
            <div className="justify-content-md-center">
              <Button
                className={popupStyles.yesButton}
                variant="danger"
                onClick={() => handlePopupDelete()}
              >
                Yes
              </Button>
              <Button variant="success" onClick={handlePopupDeleteClose}>
                Cancel
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </MainTemplate>
  );
};

export default AdminAccount;
