import { FC, useEffect, useState } from 'Vendor';
import { MainTemplate } from 'Components';
import { Button, Row, Col, Table } from 'react-bootstrap';
import styles from './AdminWave.module.scss';
import { formatDate, formatDateSingle } from 'Utils';
import { getEvaluationActivity } from 'Api';

const EvaluationActivity: FC<React.ReactNode> = () => {
  const [activityTable, setActivityTable] =
    useState<EvaluationActivityType[]>();
  useEffect(() => {
    getActivity();
  }, []);
  const getActivity = async () => {
    try {
      const resp = await getEvaluationActivity();
      setActivityTable(resp.data);
    } catch (e: any) {
      setActivityTable([]);
    }
  };
  const styleTable = {
    waveCol: {
      width: '18%',
    },
    repCol: {
      width: '15%',
    },
    statusCol: {
      width: '5%',
    },
    coachedByCol: {
      width: '7%',
    },
    dateCol: {
      width: '15%',
    },
    commentCol: {
      width: '20%'
    },
    emailSentToCol: {
      width: '15%',
    },
    emailStatusToCol: {
      width: '5%',
    },
  };
  return (
    <MainTemplate scrollableBody={true} unCenteredElements={true}>
      <h3 className={styles.title}>Evaluation Activity</h3>
      <Row className={styles.rowButton}>
        <div>
          <Button href="/admin" variant="warning">
            Back
          </Button>
        </div>
      </Row>
      <Table striped bordered hover className={styles.table}>
        <thead>
          <tr>
            <th style={styleTable.waveCol}>Wave Info</th>
            <th style={styleTable.repCol}>Rep Info</th>
            <th style={styleTable.statusCol}>Status</th>
            <th style={styleTable.coachedByCol}>By</th>
            <th style={styleTable.dateCol}>Date</th>
            <th style={styleTable.commentCol}>Comment</th>
            <th style={styleTable.emailSentToCol}>Sent To</th>
            <th style={styleTable.emailStatusToCol}>Email Status</th>
          </tr>
        </thead>
        <tbody>
          {activityTable && activityTable.length > 0 ? (
            activityTable.map((item: EvaluationActivityType) => (
              <tr key={item.id}>
                <td>
                  {item.wave} - {formatDate(item.startDate, item.endDate)}
                </td>

                <td>
                  {item.repName} - {item.team}
                </td>
                <td>{item.evaluationStatus}</td>
                <td>{item.coachedBy}</td>
                <td>{formatDateSingle(item.createdDate)}</td>
                <td>{item.emailActivityLatest?.comment}</td>
                <td>{item.emailActivityLatest?.emailSentTo}</td>
                <td>{item.emailActivityLatest?.emailStatus ? 'ok' : '-'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>No evaluation activity found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </MainTemplate>
  );
};

export default EvaluationActivity;
