import { _, FC, useState, ReactPlayer, useEffect } from 'Vendor';
import { Table, Form } from 'react-bootstrap';
import { getClientAdminRepReport } from 'Api';
import { MediaPopup } from 'Components';
import styles from './ClientAdmin.module.scss';

const popupIntialState = {
  show: false,
  name: '',
  fileUrl: '',
};

const History: FC<{ repOptions: RepType[] }> = ({ repOptions }) => {
  const [popupProps, setPopupProps] = useState(popupIntialState);
  const [repDataTable, setRepDataTable] =
    useState<ClientAdminRepReportType[]>();
  const [selectedRepOnLoad, setSelectedRepOnLoad] = useState<string>('');
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [repName, setName] = useState<string>('');

  useEffect(() => {
    if (repOptions && repOptions.length > 0) {
      const firstRep = repOptions[0];
      getTableInfoByRep(firstRep.id);
      setSelectedRepOnLoad(firstRep.id);
      setName(firstRep.name);
    }
  }, [repOptions]);

  const getTableInfoByRep = async (repId: string) => {
    setLoadingTable(true);
    await getClientAdminRepReport(repId).then((repData) => {
      setRepDataTable(repData.data);
    });
    setTimeout(() => {
      setLoadingTable(false);
    }, 300);
  };
  const handleRepChange = (e: any) => {
    const obj = {
      text: e.target.selectedOptions[0].innerText,
      value: e.currentTarget.value,
    };
    getTableInfoByRep(obj.value);
    setName(obj.text);
  };
  const handlePopupOpen = (recordings: RepRecordingType[]) => {
    const orderedRecordings = _.orderBy(recordings, (x) => x.date, 'desc');
    const latestItem = orderedRecordings[0];
    const apiUrl = process.env.REACT_APP_BASE_API_URL;

    setPopupProps({
      show: true,
      name: repName,
      fileUrl: `${apiUrl}/${latestItem.url}`,
    });
  };
  const handlePopupClose = () => {
    setPopupProps(popupIntialState);
  };
  const styleTable = {
    dateCol: {
      width: '30%',
    },
    behaviorCol: {
      width: '30%',
    },
    coachCol: {
      width: '20%',
    },
    outcomeCol: {
      width: '20%',
    },
  };
  return (
    <div className={styles.history}>
      <Form.Select title="Select Rep" onChange={handleRepChange} required>
        <option value="" disabled>
          Select Rep
        </option>
        {repOptions?.map((opt: RepType) => (
          <option value={opt.id} key={opt.id}>
            {opt.name}
          </option>
        ))}
      </Form.Select>
      <Table striped bordered hover className={styles.table}>
        <thead>
          <tr>
            <th style={styleTable.dateCol}>Date</th>
            <th style={styleTable.behaviorCol}>Behavior</th>
            <th style={styleTable.coachCol}>Coach</th>
            <th style={styleTable.outcomeCol}>Outcome</th>
          </tr>
        </thead>
        <tbody>
          {!loadingTable ? (
            repDataTable && repDataTable.length > 0 ? (
              repDataTable.map((item: ClientAdminRepReportType) => (
                <tr key={item.waveId}>
                  <td>{item.wave}</td>
                  <td>{item.subject}</td>
                  <td>{item.coach}</td>
                  <td className={styles[item.outcome]}>
                    <span>{item.outcome}</span>
                    {item.latestRecordings &&
                    item.latestRecordings.length > 0 ? (
                      <>
                        <button
                          className={styles.playButton}
                          onClick={() => handlePopupOpen(item.latestRecordings)}
                        >
                          <PlayIcon />
                        </button>
                        <span className={styles.recordingCount}>
                          {item.latestRecordings.length}
                        </span>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No active waves found</td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={4}>Loading...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <MediaPopup
        handleClose={handlePopupClose}
        show={popupProps.show}
        title={popupProps.name}
        mediaType={popupProps.fileUrl.includes('mp4') ? 'video' : 'audio'}
      >
        <ReactPlayer
          url={popupProps.fileUrl}
          width="100%"
          height="50px"
          playing={false}
          controls={true}
        />
      </MediaPopup>
    </div>
  );
};

const PlayIcon = () => {
  const playIcon = require('../../assets/images/play.png');
  return (
    <img className={styles.playIcon} src={playIcon.default} alt="Play Icon" />
  );
};
export default History;
