import { useState, useEffect, useHistory, classnames } from 'Vendor';
import { MainTemplate } from 'Components';
import { getTeams } from 'Api';
import { localStorageService } from 'Utils';
import { Form, InputGroup, Button } from 'react-bootstrap';
import styles from './ClientAdmin.module.scss';

function ClientAdmin() {
  const history = useHistory();
  const [teams, setTeamOptions] = useState<TeamType[]>();
  const [team, setTeam] = useState<string>();
  const [formStatus, setFormStatus] = useState<string>('');

  useEffect(() => {
    getTeamOption();
  }, []);

  const getTeamOption = async () => {
    const respTeam = await getTeams();
    const teamInfo: TeamType[] = respTeam.data;
    const lstTeams = teamInfo.filter((x) => x.value !== 0);
    setTeamOptions(lstTeams);
    if (lstTeams[0]) {
      const firstOption = lstTeams[0];
      setTeam(
        JSON.stringify({
          team: firstOption.name,
          teamId: firstOption.value,
        })
      );
    }
  };

  const handleTeamChange = (e: any) => {
    const obj = {
      team: e.target.selectedOptions[0].innerText,
      teamId: e.currentTarget.value,
    };
    setTeam(JSON.stringify(obj));
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      localStorageService.setItem('team-selected', team);
      history.push('/client-admin/landing');
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setFormStatus(err.data?.message);
      } else {
        setFormStatus('Something went wrong.');
      }
    }
  };

  //constant stuff
  const headerProps = {
    title: 'Status Report',
  };

  const buttonStyle = classnames('btn', 'glow', styles.button);

  return (
    <MainTemplate headerInfo={headerProps}>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <InputGroup className="mb-3">
          <Form.Select
            title="Select Team"
            onChange={handleTeamChange}
            required
            className={styles.select}
          >
            <option value="" disabled>
              Select Team
            </option>
            {teams?.map((opt: TeamType) => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        <Button variant="black" type="submit" className={buttonStyle}>
          Select Team
        </Button>
        <InputGroup className="mb-3">
          {formStatus !== '' ? (
            <p className="errorText">{formStatus}</p>
          ) : (
            <p className="statusPlaceHolder">&nbsp;</p>
          )}
        </InputGroup>
      </Form>
    </MainTemplate>
  );
}

export default ClientAdmin;
