import { axios } from 'Vendor';
import { localStorageService } from './storageService';
import { authWrapper } from 'Utils';

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const getHeader = (contentType?: string, additionalHeaders?: any): any =>
{
  let headers: any = {
    "Content-Type": `${contentType ? contentType : 'application/json'}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    ...additionalHeaders
  }
  const token = authWrapper.getAuthTokensFromStorage();
  if(token){
    headers['Authorization'] = `Bearer ${token}`
  }
  return headers

}

const apiWrapper = {
  getRequest: async <T> (
    endpoint: string,
    additionalHeaders?: any,
    contentType?: string,
    params?: any,
    responseType?: any
  ) => {
    const headers = getHeader(contentType, additionalHeaders);
    const axiosConfig = {
      headers: headers,
      params,
      responseType,
    };
    return await axios.get<T>(`${BASE_API_URL}${endpoint}`, axiosConfig);
  },
  postRequest: (
    endpoint: string,
    payload?: any,
    contentType?: string,
    additionalHeaders?: any,
    params?: any, 
    onUploadProgress?: any
  ) => {
    const headers = getHeader(contentType, additionalHeaders);
    const axiosConfig = {
      headers: headers,
      params,
      onUploadProgress,
    };
    return axios.post(`${BASE_API_URL}${endpoint}`, payload, axiosConfig);
  },
  putRequest: (
    endpoint: string,
    payload?: any,
    contentType?: string,
    additionalHeaders?: any,
    params?: any,
    onUploadProgress?: any
  ) => {
    const headers = getHeader(contentType, additionalHeaders);
    const axiosConfig = {
      headers: headers,
      params,
      onUploadProgress
    };
    return axios.put(`${BASE_API_URL}${endpoint}`, payload, axiosConfig);
  },
  
  deleteRequest: (endpoint: string, additionalHeaders?: any) => {
    const headers = getHeader(additionalHeaders);
    const axiosConfig = {
      headers: headers,
    };
    return axios.delete(`${BASE_API_URL}${endpoint}`, axiosConfig);
  }
  
};

const errorHandler = async (error: any) => {
  if (error?.response?.status === 401) {
    localStorageService.clearAll();
    window.location.href = '/login#expired';
  }
  return Promise.reject(error.response);
};

const responseHandler = (response: any) => {
  if (response.status === 200) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
};

axios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);


export default apiWrapper;