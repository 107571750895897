import { BrowserRouter, Switch, Route } from 'Vendor';
import { Authenticator } from 'Components';
import ProtectedRoute from './protectedRoute';
import PublicRoute from './publicRoute';
import {
  ClientAdmin,
  ClientAdminLanding,
  Coach,
  Login,
  Home,
  Evaluate,
  EvaluateEmail,
  EvaluateEmailDone,
  EvaluateDone,
  CreateWave,
  AdminAccount,
  CreateAccount,
  EditAccount,
  EditWave,
  AdminWave,
  CoachingActivity,
  EvaluationActivity,
  CoachingSession,
  PageNotFound,
  AdminLanding,
} from 'Pages';

const AppRoutes = () => (
  <Authenticator>
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path="/" component={Home} />
        <PublicRoute exact path="/login" component={Login} />
        <ProtectedRoute
          exact
          path="/client-admin/landing"
          component={ClientAdminLanding}
        />
        <ProtectedRoute exact path="/client-admin" component={ClientAdmin} />
        <ProtectedRoute
          exact
          path="/coach/session/:id"
          component={CoachingSession}
        />
        <ProtectedRoute exact path="/coach" component={Coach} />

        <ProtectedRoute
          exact
          path="/evaluate/email/done"
          component={EvaluateEmailDone}
        />
        <ProtectedRoute
          exact
          path="/evaluate/email/:id"
          component={EvaluateEmail}
        />
        <ProtectedRoute exact path="/evaluate/done" component={EvaluateDone} />
        <ProtectedRoute exact path="/evaluate" component={Evaluate} />
        <ProtectedRoute exact path="/admin/account" component={AdminAccount} />
        <ProtectedRoute
          exact
          path="/admin/create-account"
          component={CreateAccount}
        />
        <ProtectedRoute
          exact
          path="/admin/edit-account/:id"
          component={EditAccount}
        />
        <ProtectedRoute exact path="/admin/wave" component={AdminWave} />
        <ProtectedRoute
          exact
          path="/admin/create-wave"
          component={CreateWave}
        />
        <ProtectedRoute
          exact
          path="/admin/edit-wave/:id"
          component={EditWave}
        />

        <ProtectedRoute
          exact
          path="/admin/coaching-activity"
          component={CoachingActivity}
        />
        <ProtectedRoute
          exact
          path="/admin/evaluation-activity"
          component={EvaluationActivity}
        />

        <ProtectedRoute exact path="/admin" component={AdminLanding} />
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  </Authenticator>
);
export default AppRoutes;
