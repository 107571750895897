import { FC, classnames, useEffect, useState } from 'Vendor';
import { Button } from 'react-bootstrap';
import { MainTemplate } from 'Components';
import styles from './Home.module.scss';
import { getTeams } from 'Api';

const Home: FC<React.ReactNode> = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useEffect(() => {
    getFormInfo();
  }, []);
  const getFormInfo = () => {
    getTeams().then(() => {
      setIsMounted(true);
    });
  };
  const buttonStyles = classnames('glow', styles.buttons);
  return (
    <>
      {isMounted ? (
        <MainTemplate hideHomeButtonOnFooter="true">
          <Button variant="black" href="/coach" className={buttonStyles}>
            Coach a Rep
          </Button>
          <span className={styles.or}>or</span>
          <Button variant="black" href="/evaluate" className={buttonStyles}>
            Upload &amp; Evaluate
          </Button>
          <span className={styles.or}>or</span>
          <Button variant="black" href="/client-admin" className={buttonStyles}>
            View Status Report
          </Button>
        </MainTemplate>
      ) : null}
    </>
  );
};

export default Home;
