import { useState, useEffect, useHistory } from 'Vendor';
import { Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { MainTemplate } from 'Components';
import { createAccount, getRoles, getAccounts } from 'Api';
import styles from '../AdminWave.module.scss';

const CreateAccount = () => {
  const history = useHistory();
  const [userName, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [alias, setAlias] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [roles, setRoles] = useState<ListItemType[]>();
  const [roleId, setRoleId] = useState('');
  const [managers, setManagers] = useState<AccountType[]>();
  const [managerId, setManagerId] = useState('');
  const [team, setTeam] = useState<'Residential' | 'SMB'>('Residential');
  const [status, setStatus] = useState<'Active' | 'Inactive'>('Inactive');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isRep, setIsRep] = useState(false);

  useEffect(() => {
    getRolesData();
    getManagersData();
  }, []);

  const handleRoleChange = (e: any) => {
    setRoleId(e.target.value);
    setIsRep(e.target.options[e.target.selectedIndex].text === 'Rep');
  };

  const getRolesData = async () => {
    try {
      const rolesData = await getRoles();
      setRoles(rolesData.data);
    } catch (e: any) {
      setRoles([]);
    }
  };

  const getManagersData = async () => {
    try {
      const managersData = await getAccounts('mgr', 'Active');
      setManagers(managersData.data);
    } catch (e: any) {
      setManagers([]);
    }
  };

  const onValidateEmail = (e: any) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    setEmail(e.target.value);
    if (!email || regex.test(email) === false) {
      setError('Email is invalid.');
      setLoading(true);
    } else {
      setError('');
      setLoading(false);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data: AccountType = {
        userName,
        email,
        alias,
        firstName,
        lastName,
        roleId,
        managerId,
        team,
        status,
      };

      await createAccount(data);
      history.push('/admin/account');
    } catch (err: any) {
      if (err.data?.message) {
        setError(err.data?.message);
      } else {
        setError('Something went wrong.');
      }
    }
    setLoading(false);
  };
  return (
    <MainTemplate className={styles.admin}>
      <Form className={styles.longForm} onSubmit={onSubmit}>
        <InputGroup className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            onChange={(e: any) => setUsername(e.target.value)}
            type="text"
            value={userName}
          />
          <Form.Label>Email</Form.Label>
          <Form.Control onChange={onValidateEmail} type="email" value={email} />
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            onChange={(e: any) => setFirstName(e.target.value)}
            type="text"
            value={firstName}
          />
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            onChange={(e: any) => setLastName(e.target.value)}
            type="text"
            value={lastName}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Label>Alias</Form.Label>
          <Form.Control
            onChange={(e: any) => setAlias(e.target.value)}
            type="text"
            value={alias}
          />
          <Form.Label>Role</Form.Label>
          <Form.Select
            aria-label="Role"
            value={roleId}
            onChange={handleRoleChange}
          >
            {roles && roles.length > 0 ? (
              roles.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))
            ) : (
              <option>Loading...</option>
            )}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Label>Team</Form.Label>
          <Form.Select
            aria-label="Team"
            value={team}
            onChange={(e: any) => setTeam(e.target.value)}
          >
            {['Residential', 'SMB'].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
          <Form.Label>Manager</Form.Label>
          <>
            {isRep}
            <Form.Select
              aria-label="Manager"
              value={managerId}
              onChange={(e: any) => setManagerId(e.target.value)}
              disabled={isRep ? false : true}
            >
              {managers && managers.length > 0 ? (
                managers.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.firstName} {item.lastName}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </Form.Select>
          </>
        </InputGroup>

        <InputGroup className="mb-3">
          <Form.Label>Active</Form.Label>
          <Form.Check
            type="checkbox"
            label="Is Active?"
            checked={status === 'Active'}
            onChange={(e: any) =>
              setStatus(e.target.checked ? 'Active' : 'Inactive')
            }
          />
        </InputGroup>
        {error && <div className="error-text">{error}</div>}
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            type="submit"
            className={styles.saveButton}
            disabled={loading}
          >
            {loading ? <Spinner animation="border" /> : 'Save'}
          </Button>
          <Button
            variant="danger"
            href="/admin/account"
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </MainTemplate>
  );
};

export default CreateAccount;
