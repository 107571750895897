import { moment } from 'Vendor';
const formatDate = (start: Date, end: Date): string => {
  const strtMonth = moment.utc(start).format('MMM');
  const strtDay = moment.utc(start).format('DD');
  const endMonth = moment.utc(end).format('MMM');
  const endDay = moment.utc(end).format('DD');
  let dateRange = `${strtMonth} ${strtDay} - ${endMonth} ${endDay}`;
  if (strtMonth === endMonth) {
    dateRange = `${strtMonth} ${strtDay} - ${endDay}`;
  }
  return dateRange;
};

const formatDateSingle = (theDate: Date): string => {
  const date = moment.utc(theDate).utcOffset(-4).format('yyyy-MM-DD HH:mm:ss');
  return date;
};

export { formatDate, formatDateSingle };
