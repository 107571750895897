import { FC, classnames, useEffect } from 'Vendor';
import { Row } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import styles from './MainTemplate.module.scss';
const MainTemplate: FC<MainTemplateType> = (info: MainTemplateType) => {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const title = info.headerInfo?.title ? `- ${info.headerInfo.title}` : '';
    // Update the document title using the browser API
    document.title = `MTA Coacher ${title}`;
  });
  let classArg = [styles.mainbody, info.className];
  
  if (!info.unCenteredElements) {
    classArg.push(styles.centeredElements);
    classArg.push(styles.verticalAlignedElements);
  }
  if(info.scrollableBody){
    classArg.push(styles.scrollableBody);
  }
  let mainBodyStyle = classnames(classArg);
  return (
    <div className={styles.maincontainer}>
      <Header {...info.headerInfo}></Header>
      <Row className="s-3">
        <div id={info.mainBodyId} className={mainBodyStyle}>
          {info.children}
        </div>
      </Row>

      <Footer hideHomeButton={info.hideHomeButtonOnFooter}></Footer>
    </div>
  );
};
export default MainTemplate;
