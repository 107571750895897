import { apiWrapper } from 'Utils';

const getWave = async (waveId: string) => {
  return await apiWrapper.getRequest<WaveType>(`/api/admin/waves/${waveId}`, null);
}
const createWave = async (payload: WaveType) => {
  return await apiWrapper.postRequest('/api/admin/waves', payload);
};

const updateWave = async (waveId: string, payload: WaveType) => {
  return await apiWrapper.putRequest(`/api/admin/waves/${waveId}`, payload);
}

const uploadWaveRecord = async (payload: WaveRecordingFormType, onUploadProgress:any) => {
  const formData = new FormData();
  formData.append('formFile', payload.formFile);
  formData.append('waveId', payload.waveId);
  formData.append('order', payload.order);
  return await apiWrapper.postRequest('/api/admin/waves/recordings', formData, "multipart/form-data", null, null, onUploadProgress);
};

const replaceWaveRecord = async (payload: ReplaceWaveRecordingFormType, onUploadProgress: any) => {
  const formData = new FormData();
  formData.append('formFile', payload.formFile);
  formData.append('waveRecordingId', payload.waveRecordingId);
  formData.append('order', payload.order);
  return await apiWrapper.putRequest('/api/admin/waves/recordings', formData, "multipart/form-data", null, null, onUploadProgress);
}

const deleteWave = async (waveId: string) => {
  return await apiWrapper.deleteRequest(`/api/admin/waves/${waveId}`, null);
}
const deleteWaveRecord = async (waveRecordingId: string) => {
  return await apiWrapper.deleteRequest(`/api/admin/waves/recordings/${waveRecordingId}`, null);
}

const toolsResetAll = async () => {
  return await apiWrapper.postRequest('/api/tools/resetall');
};

export { getWave, createWave, deleteWave, deleteWaveRecord, updateWave, uploadWaveRecord, replaceWaveRecord, toolsResetAll };
