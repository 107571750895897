import {
  FC,
  useState,
  useEffect,
  useContext,
  Redirect,
  classnames,
} from 'Vendor';
import { Row, Button } from 'react-bootstrap';
import { AuthenticationContext } from 'Components';
import { authWrapper } from 'Utils';
import styles from './Footer.module.scss';
const Footer: FC<{ hideHomeButton?: boolean }> = ({ hideHomeButton }) => {
  const authContext = useContext(AuthenticationContext);
  const [logOutClick, setLogOut] = useState<boolean>(false);
  useEffect(() => {
    setLogOut(false);
  }, []);

  const onLogout = () => {
    authWrapper.removeAuthTokensFromStorage();
    authContext.setStatus(false, '', '', false, new Date());
  };
  const footerStyle = classnames(styles.footer);
  return (
    <Row className={footerStyle}>
      {!logOutClick ? (
        <AuthenticationContext.Consumer>
          {(value) =>
            value.authenticationStatus ? (
              <div>
                {!hideHomeButton ? (
                  <Button href="/" className={styles.buttons}>
                    Home
                  </Button>
                ) : null}
                <Button
                  type="button"
                  onClick={onLogout}
                  className={styles.buttons}
                >
                  Exit
                </Button>
              </div>
            ) : null
          }
        </AuthenticationContext.Consumer>
      ) : (
        <Redirect to="/login" />
      )}
    </Row>
  );
};
export default Footer;
