import { FC } from 'Vendor';
import { Modal, CloseButton } from 'react-bootstrap';
import styles from './MediaPopup.module.scss';

const MediaPopup: FC<{
  children: any;
  handleClose: any;
  show: boolean;
  title: string;
  mediaType: 'audio' | 'video';
}> = ({ handleClose, children, show, title, mediaType }) => {
  const isVideo = mediaType === 'video' ? true : false;
  return (
    <>
      {isVideo ? (
        <Modal
          className={styles.popup}
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className={styles.header}>
            <Modal.Title>{title}</Modal.Title>
            <CloseButton variant="white" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body className={styles.body}>{children}</Modal.Body>
        </Modal>
      ) : (
        <Modal
          className={styles.popupAudio}
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className={styles.body}>
            <div className={styles.audioTitle}>{title}</div>
            {children}
            <CloseButton variant="white" onClick={handleClose} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default MediaPopup;
