import { useState, useEffect, useHistory, useParams, moment } from 'Vendor';
import { Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { MainTemplate } from 'Components';
import { getWave, updateWave } from 'Api';
import styles from './AdminWave.module.scss';

const EditWave = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [subject, setSubject] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [strStartDate, setStrStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [strEndDate, setStrEndDate] = useState<string>('');
  const [team, setTeam] = useState<'Residential' | 'SMB'>('Residential');
  const [status, setStatus] = useState<'Active' | 'Inactive'>('Inactive');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWaveData();
  }, []);

  const getWaveData = async () => {
    try {
      const resp = await getWave(id);
      const wave = resp.data;
      setSubject(wave.subject);
      const strt = moment.utc(wave.startDate).format('yyyy-MM-DD');
      const end = moment.utc(wave.endDate).format('yyyy-MM-DD');
      setStartDate(wave.startDate);
      setStrStartDate(strt);
      setEndDate(wave.endDate);
      setStrEndDate(end);
      setTeam(wave.team);
      setStatus(wave.status);
    } catch (e: any) {}
  };

  const onSetStatus = (e: any) => {
    setStatus(e.target.checked ? 'Active' : 'Inactive');
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data: WaveType = {
        subject,
        startDate,
        endDate,
        team,
        status,
      };

      await updateWave(id, data);

      history.push('/admin/wave');
    } catch (err: any) {
      console.log(err);
      if (err.data?.message) {
        setError(err.data?.message);
      } else {
        setError('Something went wrong.');
      }
    }
    setLoading(false);
  };
  return (
    <MainTemplate>
      <Form onSubmit={onSubmit} className={styles.admin}>
        <Form.Label>Subject:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            onChange={(e: any) => setSubject(e.target.value)}
            type="text"
            value={subject}
          />
        </InputGroup>
        <Form.Label>Date Range:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            type="date"
            onChange={(e: any) => {
              setStartDate(e.target.value);
              setStrStartDate(e.target.value);
            }}
            value={strStartDate}
          />
          <Form.Control
            type="date"
            onChange={(e: any) => {
              setEndDate(e.target.value);
              setStrEndDate(e.target.value);
            }}
            value={strEndDate}
          />
        </InputGroup>
        <Form.Label htmlFor="file-upload">Team:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Check
            inline
            label="Residential"
            name="team"
            type="radio"
            value="Residential"
            checked={team === 'Residential'}
            onChange={(e: any) => setTeam(e.target.value)}
          />
          <Form.Check
            inline
            label="SMB"
            name="team"
            type="radio"
            value="SMB"
            checked={team === 'SMB'}
            onChange={(e: any) => setTeam(e.target.value)}
          />
        </InputGroup>
        <Form.Label htmlFor="file-upload">Active:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Check
            type="checkbox"
            label="Is Active?"
            checked={status === 'Active'}
            onChange={onSetStatus}
          />
        </InputGroup>
        {error && <div className="error-text">{error}</div>}
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            type="submit"
            className={styles.saveButton}
            disabled={loading}
          >
            {loading ? <Spinner animation="border" /> : 'Save'}
          </Button>
          <Button
            variant="danger"
            href="/admin/wave"
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </MainTemplate>
  );
};

export default EditWave;
