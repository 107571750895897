import { Route, PropTypes, Redirect, FC } from 'Vendor';
import { AuthenticationContext } from 'Components';

const PublicRoute: FC<{ component: any; path: string; exact?: boolean }> = ({
  path,
  component: Component,
  ...rest
}) => {
  return (
    <AuthenticationContext.Consumer>
      {(value) => (
        <Route
          exact
          {...rest}
          path={path}
          render={(props) =>
            value.authenticationStatus ? (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            ) : (
              <Component {...props} />
            )
          }
        />
      )}
    </AuthenticationContext.Consumer>
  );
};

PublicRoute.propTypes = {
  component: PropTypes.elementType,
  // path: PropTypes.string,
};

export default PublicRoute;
