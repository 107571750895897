import {
  _,
  FC,
  useState,
  useEffect,
  useHistory,
  useParams,
  ReactPlayer,
  classnames,
  useRef,
} from 'Vendor';
import { Row, Button } from 'react-bootstrap';
import { MainTemplate, VidPlayer } from 'Components';
import { getWaves, addCoachingSessionByRep } from 'Api';

import styles from './Coach.module.scss';

const apiUrl = process.env.REACT_APP_BASE_API_URL;

const componentTypes = {
  VIEW_VIDEO: 'VIEW_VIDEO',
  START_BUTTON: 'START_BUTTON',
};
const CoachingSession: FC<React.ReactNode> = () => {
  const history = useHistory();
  const [isMounted, setMounted] = useState<boolean>(false);
  const [hasWave, setHasWave] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [currentWave, setCurrentWave] = useState<WaveType>();
  const [recordings, setRecordings] = useState<WaveRecordingType[]>([]);
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState<number>(0);
  const [showEnded, setShowEnded] = useState<boolean>(false);
  const [showNext, setShowNext] = useState<boolean>(false);
  const [initState, setInitialState] = useState<any>({
    vidPath: '',
    component: 'START_BUTTON',
  });

  const [videoPlaying, setVideoPlaying] = useState<boolean>(true);
  const [headerInfo, setHeaderInfo] = useState<HeaderType>();
  const startButtonStyle = classnames('btn', styles.startBtn, 'glow');
  const controlButtonStyle = classnames('btn', styles.controlBtn, 'glow');
  const controlButtonStyleNoGlow = classnames('btn', styles.controlBtn);

  useEffect(() => {
    getWave().then((r) => {
      if (r && r > 0) {
        setHasWave(true);
        setMounted(true);
      }
    });
  }, []);

  const getWave = async () => {
    const respWaves = await getWaves(undefined, undefined, id);
    const waveData = respWaves.data;
    const findCurrentWave = waveData.filter((xx) => xx.currentWave);
    if (findCurrentWave && findCurrentWave.length > 0) {
      const cw = findCurrentWave[0];
      let count = 0;
      setCurrentWave(cw);
      if (cw.recordings) {
        count = cw.recordings.length;
        setRecordings(_.orderBy(cw.recordings, (x) => x.order, 'asc'));
      }
      setHeaderInfo({
        title: 'Coach A Rep',
        secondaryTitle: cw?.subject,
      });

      return count;
    }
  };
  const clickNext = () => {
    setShowEnded(false);
    setShowNext(false);
    setVideoPlaying(true);
    setInitialState({
      vidPath: `${apiUrl}/${recordings[currentVideoPlaying + 1].path}`,
      component: 'VIEW_VIDEO',
    });
    setCurrentVideoPlaying(currentVideoPlaying + 1);
  };
  const clickReplay = () => {
    setVideoPlaying(true);
  };

  const clickStart = () => {
    setInitialState({
      vidPath: `${apiUrl}/${recordings[0].path}`,
      component: 'VIEW_VIDEO',
    });
    setCurrentVideoPlaying(0);
  };

  const clickDone = async () => {
    history.push('/');
  };

  const videoStarted = async () => {
    if (currentVideoPlaying + 1 === recordings.length) {
      await addCoachingSessionByRep(id);
    }
  };

  const videoEnded = () => {
    setVideoPlaying(false);
    if (currentVideoPlaying + 1 === recordings.length) {
      setShowEnded(true);
      setShowNext(false);
    } else {
      setShowNext(true);
      setShowEnded(false);
    }
  };

  const renderComponent = () => {
    switch (initState.component) {
      case componentTypes.VIEW_VIDEO:
        return (
          <ReactPlayer
            url={initState.vidPath}
            width="100%"
            height="100%"
            playing={videoPlaying}
            controls={false}
            onStart={() => videoStarted()}
            onEnded={() => videoEnded()}
          />
        );

      case componentTypes.START_BUTTON:
        return (
          <Button
            variant="black"
            type="submit"
            className={startButtonStyle}
            onClick={() => clickStart()}
          >
            Start Coaching Session
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isMounted ? (
        hasWave ? (
          <MainTemplate
            className={styles.coachingSession}
            headerInfo={headerInfo}
          >
            <div className={styles.stage}>
              {renderComponent()}

              <div className={styles.btnRow}>
                {showEnded ? (
                  <>
                    <Button
                      variant="black"
                      className={controlButtonStyle}
                      onClick={() => clickDone()}
                    >
                      Done
                    </Button>
                    <span className={styles.or}>or</span>
                    <Button
                      variant="black"
                      className={controlButtonStyleNoGlow}
                      onClick={() => clickReplay()}
                    >
                      Replay
                    </Button>
                  </>
                ) : null}
                {showNext ? (
                  <>
                    <Button
                      variant="black"
                      className={controlButtonStyle}
                      onClick={() => clickNext()}
                    >
                      Next
                    </Button>
                    <span className={styles.or}>or</span>
                    <Button
                      variant="black"
                      className={controlButtonStyleNoGlow}
                      onClick={() => clickReplay()}
                    >
                      Replay
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          </MainTemplate>
        ) : (
          <MainTemplate>
            <h2>No active waves created</h2>
          </MainTemplate>
        )
      ) : null}
    </>
  );
};

export default CoachingSession;
