import { FC, useState, useRef } from 'Vendor';
import {
  Modal,
  CloseButton,
  Form,
  InputGroup,
  FormControl,
  Button,
  ProgressBar,
  Spinner,
} from 'react-bootstrap';
import styles from './WaveRecording.module.scss';

const AddOrReplaceWaveRecording: FC<{
  title: string;
  id?: string | undefined;
  order: string,
  show: boolean;
  percentage: number;
  loading: boolean;
  formStatus: string,
  handleClose: any;
  handleSave: any;
}> = ({ title, id, order, show, percentage, loading, formStatus, handleClose, handleSave }) => {
  const resetter = useRef<any>();
  const [video, setVideo] = useState<any>({});

  return (
    <Modal
      className={styles.popup}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={styles.header}>
        <Modal.Title>{title}</Modal.Title>
        <CloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Form onSubmit={(e: any) => { 
          e.preventDefault();
          handleSave(id, order, video);
          }}>
          <InputGroup className="mb-3">
            <FormControl
              ref={resetter}
              onChange={(e: any) => setVideo(e.target.files[0])}
              required
              type="file"
              max={1}
              accept=".mp4"
            />
          </InputGroup>
          {loading && (
            <div className="mb-10">
              <ProgressBar now={percentage} label={`${percentage}%`} />
            </div>
          )}
          {formStatus && <div className="error-text">{formStatus}</div>}
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              type="submit"
              className="btn submit-btn"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" /> : 'Upload'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddOrReplaceWaveRecording;
