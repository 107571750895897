import {
  FC,
  useState,
  useEffect,
  classnames,
  useParams,
  useHistory,
} from 'Vendor';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { MainTemplate } from 'Components';
import {
  getClientAdminRepReport,
  sendEvaluationMessage,
  getEmailEvaluateSettings,
} from 'Api';
import styles from './Evaluate.module.scss';

const EvaluateEmail: FC<React.ReactNode> = () => {
  const { id } = useParams<{ id: string }>();
  const headerInfo: HeaderType = {
    title: 'Upload & Evaluate',
  };
  const history = useHistory();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [hasCurrentWeek, setHasCurrentWeek] = useState<boolean>(false);
  const [waveId, setCurrentWaveId] = useState<string>('');
  const [repName, setRepName] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [formStatus, setFormStatus] = useState<string>('');
  const [lastActivity, setLastActivity] = useState<string>('');
  const [message, setMessage] = useState<string>();
  const [outcome, setOutcome] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [emailSettings, setEmailSettings] = useState<SettingsEmailEvaluate>({
    maxLetters: 2000,
    successfulMessage: '',
    unsuccessfulMessage: '',
  });

  useEffect(() => {
    loadUser().then(() => {
      setIsMounted(true);
    });
  }, []);
  const loadUser = async () => {
    await getClientAdminRepReport(id).then(async (r) => {
      const repWeeks = r.data;
      const lstRepCurrentWeek = repWeeks.filter((x) => x.currentWave);
      if (lstRepCurrentWeek && lstRepCurrentWeek.length > 0) {
        const repInfo = lstRepCurrentWeek[0];
        setHasCurrentWeek(true);
        setCurrentWaveId(repInfo.waveId);
        setRepName(repInfo.repName);
        setLastActivity(repInfo.outcome);
        setSubject(repInfo.subject);
        setOutcome(repInfo.outcome);
        await getEmailEvaluateSettings(repInfo.team).then((resp) => {
          setEmailSettings(resp.data);
          switch (repInfo.outcome) {
            case 'Successful':
              setMessage(resp.data.successfulMessage);
              setComment(resp.data.successfulMessage);
              break;
            case 'Unsuccessful':
              setMessage(resp.data.unsuccessfulMessage);
              setComment(resp.data.unsuccessfulMessage);
              break;
            //most likely will not reach here.
            case 'Coached':
              setMessage('');
              break;
            case 'Inactive':
              setMessage('');
              break;
          }
        });
      }
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await sendEvaluationMessage(comment, id, waveId);
      history.push('/evaluate/email/done');
    } catch (err: any) {
      if (err.data?.message) {
        setFormStatus(err.data?.message);
      } else {
        setFormStatus('Something went wrong.');
      }
    }
  };
  const formGroupStyle = classnames('mb-3', styles.fieldRow);
  const buttonStyle = classnames('btn', 'glow', styles.button);

  return (
    <MainTemplate headerInfo={headerInfo}>
      {isMounted ? (
        hasCurrentWeek ? (
          <Form className={styles.form} onSubmit={handleSubmit}>
            <p className={styles.paragraph}>
              <strong>Upload Complete:</strong> {repName}, Evaluation -{' '}
              {outcome}
            </p>
            <h4 className={styles.header}>Rep Evaluation Note:</h4>
            <InputGroup className={formGroupStyle}>
              <Form.Control
                as="textarea"
                onChange={(e) => setComment(e.currentTarget.value)}
                rows={7}
                maxLength={emailSettings.maxLetters}
                placeholder={`Max characters ${emailSettings.maxLetters}`}
                defaultValue={message}
                className={styles.textArea}
              />
            </InputGroup>
            <InputGroup className={formGroupStyle}>
              <Button variant="black" type="submit" className={buttonStyle}>
                Submit Note
              </Button>
            </InputGroup>
            <InputGroup className={formGroupStyle}>
              {formStatus !== '' ? (
                <p className="errorText">{formStatus}</p>
              ) : (
                <p className="statusPlaceHolder">&nbsp;</p>
              )}
            </InputGroup>
          </Form>
        ) : (
          <h2 className={styles.h2Error}>No active waves created</h2>
        )
      ) : null}
    </MainTemplate>
  );
};

export default EvaluateEmail;
