import isObjectLike from 'lodash/isObjectLike';
import { isJSONString } from './validations';

class StorageService {
    storage: Storage;
    constructor(storage: Storage) {
        this.storage = storage;
    }

    setItem(key: any, value: any) {
        let setValue = value;
        if (isObjectLike(value)) {
            setValue = JSON.stringify(value);
        } 
        return this.storage.setItem(key, setValue);
    }

    getItem(key: any) {
        const value = this.storage.getItem(key) ||"";
        return isJSONString(value) ? JSON.parse(value) : value;
    }

    removeItem(key: any) {
        return this.storage.removeItem(key);
    }

    clearAll() {
        this.storage.clear();
    }

    removeItems(keys: any[]) {
        keys.forEach((key: any) => this.removeItem(key));
    }
}

export const localStorageService = new StorageService(window.localStorage);