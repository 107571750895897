import { MainTemplate } from 'Components';

const PageNotFound: React.FC<React.ReactNode> = () => {
  return (
    <MainTemplate>
      <h2>Page Not Found</h2>
    </MainTemplate>
  );
};

export default PageNotFound;
