import { FC, classnames } from 'Vendor';
import styles from './RepGridButton.module.scss';

const RepGrid: FC<RepGridButtonType> = (info: RepGridButtonType) => {
  const fileUrl = info.fileUrl;
  const showCount = info.count > 0;
  const buttonStyle = classnames(styles.button, styles[info.status]);
  const countButtonInfo: CountButtonType = {
    count: info.count,
  };

  return (
    <button
      className={buttonStyle}
      disabled={!showCount}
      onClick={() => {
        if (showCount) {
          info.handleClick(info.name, fileUrl);
        }
      }}
    >
      <span className={styles.name}>{info.name}</span>
      {showCount ? (
        <PlayIcon />
      ) : (
        <div className={styles.placeHolderIcon}></div>
      )}
      {showCount ? <CountButton {...countButtonInfo} /> : null}
    </button>
  );
};

type CountButtonType = {
  count: number;
};
const CountButton = (info: CountButtonType) => (
  <span className={styles.count}>{info.count}</span>
);

const PlayIcon = () => {
  const playIcon = require('../../assets/images/play.png');
  return (
    <img className={styles.playIcon} src={playIcon.default} alt="Play Icon" />
  );
};

export default RepGrid;
