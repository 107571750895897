import { FC, classnames } from 'Vendor';
import { Row, Col } from 'react-bootstrap';
import styles from './Header.module.scss';

const Header: FC<HeaderType | undefined> = (info?: HeaderType) => {
  //default logo
  const image = require('../../assets/images/mta-coacher.png');
  // const currentTime = moment.utc(new Date()).format('MMMM DD, h:mma');
  const headerMainStyle = classnames(styles.header, 'p-3', 'h-20');
  const imagePath = info?.logoPath ?? image.default;
  const logoAlt = info?.title ?? 'MTA Sales Way Coacher Logo';

  return (
    <Row className={headerMainStyle}>
      {info?.title && !info?.secondaryTitle ? (
        <Col>
          <h1 className={styles.h1}>{info.title.toUpperCase()}</h1>
        </Col>
      ) : null}
      {info?.secondaryTitle ? (
        <Col>
          <h3 className={styles.h3}>
            <span>Today's Focus:</span>
            <br />
            {info.secondaryTitle}
          </h3>
        </Col>
      ) : null}
      <Col className="d-flex justify-content-end">
        <img src={imagePath} alt={logoAlt} />
      </Col>
    </Row>
  );
};
export default Header;
