import { useState, useEffect, useRef, classnames } from 'Vendor';
import { MainTemplate } from 'Components';
import { localStorageService } from 'Utils';
import { Tabs, Tab } from 'react-bootstrap';
import { getReps } from 'Api';
import styles from './ClientAdmin.module.scss';
import Report from './Report';
import History from './History';
import Schedule from './Schedule';
import './Landing.scss';

function ClientAdminLanding() {
  const isMounted = useRef(false);
  const [team, setTeam] = useState<any>({});
  const [repOptions, setRepOptions] = useState<any>();
  const [key, setKey] = useState('summary');
  const teamFromStorage = localStorageService.getItem('team-selected');

  useEffect(() => {
    // Becomes true on component mount
    setTeam(teamFromStorage);
    if (team !== {}) {
      isMounted.current = true;
      getRepOptions(teamFromStorage.teamId);
    }
    // becomes false on unmount
    return () => {
      isMounted.current = false;
      localStorageService.removeItem('current-tab');
    };
  }, []);

  const getRepOptions = async (teamId: string) => {
    const respRep = await getReps(teamId, 'Active');
    const repInfo: RepType[] = respRep.data;
    setRepOptions(repInfo);
  };

  const onSelectTab = (k: any) => {
    setKey(k);
    localStorageService.setItem('current-tab', k);
  };
  //constant stuff
  const headerProps = {
    title: 'Status Report'
  };

  const tabStyle = classnames('nav-fill', styles.statusTab);

  return (
    <>
      {isMounted.current ? (
        <MainTemplate className={styles.tabbedBody} headerInfo={headerProps} unCenteredElements={true}>
          <Tabs
            activeKey={key}
            onSelect={(k: any) => onSelectTab(k)}
            className={tabStyle}
            transition={true}
            mountOnEnter={true}
            unmountOnExit={false}
          >
            <Tab eventKey="summary" title="Team Summary">
              <Report />
            </Tab>
            <Tab eventKey="history" title="History By Rep" className={styles.withBorder}>
              <History repOptions={repOptions} />
            </Tab>
            <Tab eventKey="schedule" title="Coaching Schedule">
              <Schedule teamId={team.teamId} />
            </Tab>
          </Tabs>
        </MainTemplate>
      ) : null}
    </>
  );
}

export default ClientAdminLanding;
