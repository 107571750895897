import { _, FC, useState, ReactPlayer } from 'Vendor';
import styles from './RepGrid.module.scss';
import { RepGridButton, MediaPopup } from 'Components';

export const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const popupIntialState = {
  show: false,
  name: '',
  fileUrl: '',
};

const RepGrid: FC<RepGridType> = (info: RepGridType) => {
  const [popupProps, setPopupProps] = useState(popupIntialState);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handlePopupOpen = (name: string, fileUrl: string) => {
    setPopupProps({
      show: true,
      name: `${name}`,
      fileUrl: fileUrl,
    });
  };
  const handlePopupClose = () => {
    setPopupProps(popupIntialState);
  };
  const reps = info.items;

  let pagination = [];
  if (info.totalPages && info.totalPages > 1) {
    for (let idx = 1; idx <= info.totalPages; idx++) {
      pagination.push(idx);
    }
  }

  let fillerButtons = [];

  let users: RepGridButtonType[] = [];
  if (reps) {
    for (const userStat of reps) {
      let userStatInfo: RepGridButtonType = {
        name: userStat.name,
        status: userStat.lastCoached ? 'Coached' : 'Inactive',
        fileUrl: '',
        count: userStat.latestRecordings.length,
        handleClick: handlePopupOpen,
      };
      if (userStat.latestRecordings.length > 0) {
        const orderedRecordings = _.orderBy(
          userStat.latestRecordings,
          (x) => x.date,
          'desc'
        );
        const latestItem = orderedRecordings[0];
        userStatInfo.status = latestItem.status;
        userStatInfo.fileUrl = latestItem.url;
      }

      users.push(userStatInfo);
    }
    //multiply to ten for number of items
    if (info.totalPages && info.items) {
      for (let idx = 1; idx <= 10 - info.items.length; idx++) {
        fillerButtons.push(idx);
      }
    }
  }
  return (
    <>
      <div className={styles.repgrid}>
        {users.map((buttonProps: RepGridButtonType, index) => (
          <RepGridButton
            {...buttonProps}
            key={buttonProps.name + index}
          ></RepGridButton>
        ))}
        {fillerButtons.map((page: number) => (
          <button key={page} className={styles.fillerButton}>
            {page}
          </button>
        ))}
        <MediaPopup
          handleClose={handlePopupClose}
          show={popupProps.show}
          title={popupProps.name}
          mediaType={popupProps.fileUrl.includes('mp4') ? 'video' : 'audio'}
        >
          <ReactPlayer
            url={`${REACT_APP_BASE_API_URL}/${popupProps.fileUrl}`}
            width="70%"
            height="50px"
            playing={false}
            controls={true}
          />
        </MediaPopup>
      </div>
      {pagination.length > 0 ? (
        <div className={styles.pagination}>
          <button
            className={styles.arrows}
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
                info.handlePaginationClick(
                  info.team,
                  info.waveId,
                  currentPage - 1
                );
              }
            }}
          >
            &lt;
          </button>
          {pagination.map((page: number) => (
            <button
              key={page}
              className={currentPage === page ? styles.active : ''}
              onClick={() => {
                setCurrentPage(page);
                info.handlePaginationClick(info.team, info.waveId, page);
              }}
            >
              {page}
            </button>
          ))}
          <button
            className={styles.arrows}
            onClick={() => {
              if (currentPage < pagination.length) {
                setCurrentPage(currentPage + 1);
                info.handlePaginationClick(
                  info.team,
                  info.waveId,
                  currentPage + 1
                );
              }
            }}
          >
            &gt;
          </button>
        </div>
      ) : null}
    </>
  );
};

export default RepGrid;
